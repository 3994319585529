import React, { useEffect, useRef, useState } from "react";
import { OrderView } from "./views/orderView";
import { OrderFormModule } from "./OrderFormModule";
import { ProductionFormModule } from "./ProductionFormModule";
import SVGProductionModule from "./SVGProductionModule";
import { api } from "./api";

export default function OrderManager( props ){
   
    const { lang, window } = props;
    const objectId = props.window.objectId;

    const tabs = [
        {
            label: 'order_edit',
            component: OrderFormModule
        },
        {
            label: 'manual_production',
            component: ProductionFormModule
        }
    ];

    const [ isLoading, setLoading ] = useState( true );
    const [ activeTab, setActiveTab ] = useState( props.defaultActiveTab || 0 );
    const DynamicComponent = tabs[ activeTab ]?.component || null;
    const [ object, setObject ] = useState( null );

    useEffect( () => {
        /*
        const bootstrap = async () => {
            console.log( 'window id ', window, window.object, window.objectId );
            
            const res = await api.send(
                'Order',
                'order/'+window.objectId,
                false,
                {
                    type: 'GET',
                }
            );

            if( res.code === 200 ){
                let object = response.data;
                setObject( object );
                props.updateTitle( self.props.makeWindowTitle( object , ['name']) );
    
           
            }
        };

        bootstrap();
        */
    }, [] )

    return(
        <div className="">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
            {
                    tabs &&
                    tabs.map( ( tab, i ) => {
                        return (
                            <li class="nav-item" role="presentation"
                                key={ `${tab.label}_${i}` }
                                id={ `${tab.label}_${i}` }
                                
                                dataToggle="pill"
                                data-target={ `#${tab.label}_${i}` }
                                type="button"
                                onClick={ () => setActiveTab( i ) }
                            >
                                <button className={`nav-link ${ activeTab === i && 'active'}`} id="home-tab" data-toggle="tab" data-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                    { lang[ tab.label ] }
                                </button>
                            </li>
                        )
                    })
            }
            </ul>
            <div class="p-2">
            {
                DynamicComponent &&
                <DynamicComponent 
                    prepareEditableAttributes={props.prepareEditableAttributes}
                    objectId={objectId}
                    parent={props.parent}
                    activeObjects={props.activeObjects}
                    lang={props.lang}
                    tabIndex={props.tabIndex}
                    updateState={props.updateState}
                    openWindow={props.openWindow}
                    windowId={props.window.id}
                    onChange={props.onObjectChange}
                    closeWindow={props.closeWindow}
                    updateTitle={props.updateTitle}
                    makeWindowTitle={props.makeWindowTitle}
                />
            }
            </div>
            
        </div>
    )
}