import React, { Fragment, Component, useState, useEffect } from 'react';
import { api } from '@/modules/api';
import { sanitizeDomId } from '@/utils/objectSanitizer';
import {Buffer} from 'buffer'
import lang from "@/lang/se.json";
import Loading from '@/components/loading';
import { useMainStore } from '../mainStore';
//var JSZip = require("jszip");

export default function SVGToPDFConverter( props ){


    const [ fileQuantity, setFileQuantity ] = useState( 0 );
    const { pdfFiles, setPdfFiles } = useMainStore();
    const [ isLoading, setLoading ] = useState( true );

    useEffect( () => {
        const bootstrap = async() => {
            
            let sheets = Object.values( { ...props.svgPages } );
            
            // let contents = [];
            // let zip = new JSZip();
            
             let fd = new FormData();
             sheets.map( (sheet,i) => {
                let j = i+1;
                let color = sanitizeDomId( sheet.color );
               // let domId = "s_"+sheet.material.replaceAll('_','-')+'-'+color+'-'+j;
                let domId = `p_${props.label}_${sheet.material.replaceAll('_','-')}-${color}`;
    //             let domId = "s_"+this.props.label+'-'+sheet.material.replaceAll('_','-')+'-'+sheet.color.replaceAll('/','-')+'_'+j;
                let material = lang.materials[sheet.material] || sheet.material;
                // fileName = "s_"+this.props.label+'-'+material.replaceAll('(','-').replaceAll(')','_')+'-'+sheet.color.replaceAll('/','-')+'_'+j;
                let fileName =  domId;
                let base64doc = Buffer.from(decodeURIComponent(encodeURIComponent( document.querySelector('#'+domId).outerHTML )));
                // fd.append( "files[]", domId+'-ark-'+j );
                fd.append( fileName+'-ark-'+j, base64doc );
     //            zip.file( domId+'-ark-'+j+'.svg', base64doc, { base64: true } );
      //            zip.file( domId+'-ark-'+j+'.pdf', base64doc, { binary: true } );
                 
                 
            });
           //  let orderId = this.state.object.id;
     
    
            const res = await api.requestPDFFiles( fd );
            if( res.code === 200 ){
                setPdfFiles( res.data );
                setLoading( false );
            }

        }

        bootstrap();
        
        // wrapper.append = files;
         
         /*
         console.log( 'zip ', zip );
         zip.generateAsync({type:"blob"})
         .then(function(content) {
             // see FileSaver.js
             fileDownload(content, orderId+".zip");
         });
         */

    }, [] )

    return (
        isLoading ?
        (
            <Loading />
        ) :
        (
            <div>
                <div className="">
                    <h6 className="m-2"><i className="fa fa-file-pdf mr-2"/>PDF-filer</h6>
                </div>
                
                {
                    pdfFiles.map( (file,i) => {
                        const url = `${import.meta.env.VITE_APP_PDF_API_URL}${file.url}`;
                        return ( 
                            <li className="list-group-item border-card list-group-item-action" key={i}>
                                <p className="mb-1">
                                    <a href={url} download target="_blank"><small>{file.label}</small></a>
                                </p>
                            </li>
                        )
                    })
                }
                
            </div>

        )

    );
}