import { useMainStore } from "../mainStore";
import { svgSheetConfig } from "./config";
import { getPixelConverter } from './pixelConverter';

export default class Sheet{

    constructor( material, color, tape ){
        const store = useMainStore.getState();
        this.pc = getPixelConverter( store.svgSheet.output_method );
        this.groups = [];
        this.maxHeight = this.pc.toPixel( store.svgSheet.height );
        this.maxWidth = this.pc.toPixel( store.svgSheet.width );
        this.material = material;
        this.tape = tape || '';
        this.color = color;
    }

    setGroups( groups ){
       this.groups = [ ...groups ]; 

    }

    addGroup( group ){
        this.groups.push( group );
    }

    calculateUsedArea(){
        let totalArea = 0;
        this.groups.forEach( g => {
            let area = g.width * g.height;

            totalArea += area;
        });

        let maxArea = this.maxHeight * this.maxWidth;
        let percentage = (totalArea / maxArea) * 100;
        console.log( 'Percentage used of sheet ', percentage.toFixed(2)+'%' );
    }

}