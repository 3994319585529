
import { useMainStore } from '@/stores/main.js';
import { Buffer } from 'buffer';
import { useTranslation } from 'react';
import i18n from 'i18next';


const getEnvs = () => {
    const apiURL = import.meta.env.VITE_APP_API2_URL;
    const apiKey = import.meta.env.VITE_APP_API2_KEY;

    return { apiURL, apiKey };
}

const handleResponse = ( jsonResponse ) => {
    const { addNotification, removeNotification } = useMainStore.getState();
    const message = jsonResponse.message || jsonResponse.error;
    const type = jsonResponse?.error?.length > 0 ? 'error' : 'message';
    if( message !== false && message.length > 0 ){
        const notifications = addNotification( { message, type } );
        const index = notifications.findIndex( ( n ) => n.message === message);
        setTimeout( () => {
            
            if ( index !== -1 ) {
                removeNotification( index );
            }
          }, notifications[ index ].delay );
    
    }
}
   
const doRequest = async ( resource, method, parameters ) => {

    const { apiURL, apiKey } = getEnvs();
    
    const credentials = useMainStore.getState().auth;
    const token = credentials.token;
    
    if( typeof parameters === 'undefined' )
        parameters = [];
    
    let requestOptions = {
        method: method,
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${apiKey}`,
            'x-auth': token

        },
        redirect: 'follow'
    };

    let query = '';
    if( Object.entries( parameters ).length > 0 && method !== 'GET' )
        requestOptions.body = JSON.stringify( parameters );
    else if( Object.entries( parameters ).length > 0 && method === 'GET' ){
        query = '?'+new URLSearchParams( parameters ).toString();
        
    }
    const response = await fetch( apiURL+resource+query , requestOptions );
    const res = await response.json();
    handleResponse( res );
    return res;

    
};

export const post = async ( resource, object ) => {
    return await doRequest( resource, 'POST', { ...object } );
};

export const put = async ( resource, object ) => {
    return await doRequest( resource, 'PUT', { ...object } );
};

export const patch = async ( resource, object ) => {
    return await doRequest( resource, 'PATCH', { ...object } );
};

export const get = async ( resource, parameters ) => {
    return await doRequest( resource, 'GET', { ...parameters });
};

export const del = async ( resource ) => {
    return await doRequest( resource, 'DELETE', {} );
}

export const list = async ( resource, parameters ) => {

    let params = { ...parameters };
    params.sort = parameters.sortBy+"_"+parameters.sortDir;
    let query = new URLSearchParams( params ).toString();

    return await doRequest( resource+'?'+query, 'GET' );
};

export const login = async ( { username, password } ) => {

    const { apiURL, apiKey } = getEnvs();
    i18n.changeLanguage( useMainStore.getState().locale );

    let credentials = Buffer.from( JSON.stringify( { username, password } ) ).toString('base64');
        const response = await fetch( `${apiURL}/auth/login`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${apiKey}`
    
            },
            body : JSON.stringify( { credentials: credentials, scope: true, settings:true } )
        });
        if( !response.ok ){
            const res = await response.json();
            const error = i18n.t( 'error_codes.001' );
            throw new Error( `${error} ${i18n.t(res.error)}` );

        } 
        return response;


};

export const logout = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${apiKey}` );
    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    const response = await fetch( this.apiRoot+'auth/logout', requestOptions );
    const result = await response.json();


    return result;
    
}


