import { useMainStore } from "../mainStore";

export default class Envelope {

    constructor( template ){
        const store = useMainStore.getState();
        this.constraint = { ...store.envelope };
       
        if( typeof template !== 'undefined' ){
            this.setConstraintByTemplate( template );
        }

    }

    setConstraint( width, height, margin ){
        this.constraint = { width, height, wrappingMargin: margin };
        return this;
    }

    setConstraintByTemplate( productTemplate ){
        let { height, width } = productTemplate;
        if( height > this.constraint.height )
            this.constraint.height = height;

        if( width > this.constraint.width )
            this.constraint.width = width;
        return this;
    }

    setMargin( margin ){
        this.constraint.margin = parseInt( margin );
        return this;
    }
    /**
     * 
     * @param { sign template } template 
     * @returns the margin between the signs, if the shape is oval, the items need a margin in between
     */
    getItemMargin( template ){
        return template.shape !== 'oval' ? 0 : this.constraint.itemMargin;
    }

    getMaxRowsandColumnsByTemplate( template ){
        let { constraint } = this;
        let maxRowQty = Math.floor( parseInt( constraint.height ) / ( parseInt(template.height ) + this.getItemMargin(template) ) ) || 1;
        let maxColQty = Math.floor( parseInt( constraint.width ) / ( parseInt(template.width ) + this.getItemMargin(template) ) ) || 1;

        return { maxRowQty, maxColQty };
    }

    getMaxQuantityByTemplate( template ){
        let { maxRowQty, maxColQty } = this.getMaxRowsandColumnsByTemplate( template );
        let qty = maxRowQty * maxColQty || 1;
        return qty;
    }

}