//svgConfig
import CryptoJS from 'crypto-js/crypto-js';
import { sanitizeCompoundingVariable } from '@/utils/objectSanitizer';
import TextBlock  from './blocks/textBlockv3.jsx';
import {HoleBlock}  from './blocks/holeBlock';
import {FrameBlock} from './blocks/frameBlock';
import {SymbolBlock} from './blocks/symbolBlock';
import QrCodeBlock from './blocks/qrcodeBlock';

const fonts = [
    {
        name: 'Gravur Condensed',
        asset: '/assets/fonts/GravurCondensed-Regular.ttf',
        // multiplier is used to enlarge the font so that a character is the size of the whole font area including top and bottom margins.
        multiplier: 1.426327,
        // top_margin is the distance between the top area of the font where ÅÄÖ dots is shown and the actual font top.
        top_margin: 0.295,
        // bottom_margin: similarily as top_margin this is where the jpg etc. uses space in the bottom of the font.
        bottom_margin: 0.295,
        // text_top is an extra positioning margin depending on which textAnchor is used and font. Gravur Condensed needs a top margin to be positioned correctly
        // positions upward
        top_offset: -0.05,
        // positions downward
        ascending_offset: 0.0,
       
        letter_spacing: '0.5px'
    },
    {
        name: 'ArialNarrow',
        multiplier: 1.39,
        top_margin: 0.285,
        // bottom_margin: similarily as top_margin this is where the jpg etc. uses space in the bottom of the font.
        bottom_margin: 0.295,
        top_offset: 0,
        ascending_offset: 0,
        letter_spacing: '0.5px', 
        asset: '/assets/fonts/ArialNarrow.ttf',
    }
];

// drill bit offset in mm, this is the radius of the 2mm drill used
export const drillBitOffset = 0;

const units = {
    laser: {
        toPixel: 3.7813,
        toMM: 0.269045,
        text_top: 0.346
    },
    web: {
        toPixel: 3.71002,
        toMM: 0.26954,
        text_top: 0.265
    }
};

export const svgSheetConfig = {
    width: 602,
    height: 607,
    output_method: 'laser',
    sheet_compound_criteria: [ "material", "color", 'tape' ],
    sign_compound_criteria: [ 'diameter', 'isOval', 'width', 'height' ],
    collectionConfig: {
        width: 600,
        height: 300,
        margin: 5,
        itemMargin: 2
    },
    display_envelope_title: true
};


export const outputMethod = 'laser';
export const componentBlocks = {
    TextBlock         : TextBlock,
    HoleBlock         : HoleBlock,
    FrameBlock        : FrameBlock,
    SymbolBlock       : SymbolBlock,
    QrCodeBlock       : QrCodeBlock
};

export function getFonts(){
    return fonts;
}

export function getFont( fontName ){
    return fonts.find( f => f.name === fontName );
}

export function getUnitsByOutputMethod( outputMethod ){
    return units[outputMethod];
}

export function getMaterialAndColorClass( product ){
    let string = '';
    svgSheetConfig.sheet_compound_criteria.map( i => string += product[i] );

    let hash = CryptoJS.HmacSHA1( string, '').toString();
    return hash;
}

export function getSVGProductClass( product ){
    let p = { ...product };
    let hasFoamTape = product.tape == "foam_tape" ? true : false;
    p.isOval = product.shape === 'oval' ? true:false;
    let string = '';
    svgSheetConfig.sign_compound_criteria.map( i => string += sanitizeCompoundingVariable( p[i] ) );
    
    let hash = CryptoJS.HmacSHA1( string + sanitizeCompoundingVariable( hasFoamTape ), '' ).toString();
    return hash;
}

