import React from 'react';
import {windowManager} from '../windowManager';
import {firstBy} from 'thenby';
import CryptoJS from 'crypto-js/crypto-js';
import ReactDOMServer from 'react-dom/server';
import {PackingSortProduct,PackingSortArticle, PackingSortAccessory,PackingSortShopItem, PackingProductRowList} from './views/production/packingSortingComponents';
import {
    ProductionSortProduct,
    ProductionSortArticle,
    ProductionSortAccessory,
    ProductionSortShopItem,
    ProductionProductRowList,
    ProductionSortTerminalMarking,
    ProductionSortSignMarking
} from './views/production/productionSortingComponents';
import {printable,downloadable} from '../printable';
import {objectDatadump} from '../utils/objectDatadump';
import { accountingNumbers } from '../config';
import { api } from './api';
import { sanitizeVars } from '@/utils/objectSanitizer';

import getOrderMaterials from '@/utils/getOrderMaterials';
import { useMainStore } from '../mainStore.js';
import  fileDownload  from 'react-file-download';
import lang from "@/lang/se.json";;

export class ProductionFormModule extends React.Component{

  constructor( props ){
    super( props )
    const store = useMainStore.getState();
    this.store = store;
    this.state = {
      object : props.object,
      production: false,
      packing: false,      
    };
    this.wm = new windowManager();

    this.sort1 = React.createRef();
    this.sort2 = React.createRef();
    this.sort3 = React.createRef();
    this.sort4 = React.createRef();
    this.pdfFilesWrapper = React.createRef();

  //  this.productionRef = React.createRef();
  }

  updateWindowTitle = ( e ) => {

    this.props.updateTitle( e.target.value );

  }

  buildBlockClass( prod, blockNr ){

    let rows = '';
    let block = prod.blocks[blockNr];

    if( typeof block.rows !== 'undefined' && block.rows.length > 0 ){
      let i;
      for(i in block.rows){
        let r = block.rows[i];
        rows = rows + r.alignment + r.size +  Boolean(r.sameRow);
      }
    }
    let  vars = {
      frame : block.frame,
      image : block.image_label,
      diameter : block.diameter,
      thickness : block.thickness,
      maximized : block.maximized,
      position : block.position
    };

    vars = sanitizeVars( vars );


/*
    let frame = ( typeof block.standard_frame !== 'undefined' ? 1 : 0 );
    let image = ( typeof block.image_label !== 'undefined' ? block.image_label : 0 );
    let diameter = ( typeof block.diameter !== 'undefined' ? 1 : 0 );
    let thickness = ( typeof block.thickness !== 'undefined' ? 1 : 0 );
    let maximized = 0;
    let position = 0;
*/

//    let balign = '';

    if( block.maximized === true || block.maximized === "true" ) {
      vars.position = 1;
//      balign = 1;
    } else {
      if( block.type === "TextBlock" && ( block.size_like_content === true || block.size_like_content === "true" ) ){

        let top = prod.margin_top;
        if( typeof block.position !== 'undefined' )
          top = block.position.top;

        vars.position = prod.height - top - block.height;
      } else {
        if( typeof block.position !== 'undefined' )
          vars.position = block.position.left +''+ ( typeof block.position.right === 'undefined' ? '': block.position.right ) +''+ block.position.top +''+ ( typeof block.position.bottom === 'undefined' ? '': block.position.bottom );

      }
//      balign = block.vertical_block_alignment + block.horizontal_block_alignment;
    }
    if( prod.product_type !== "Rectangular" )
      vars.position = '';

    let code = vars.frame+vars.image+vars.diameter+vars.thickness+vars.maximized+vars.position+rows;
    return code;


  }

  getClassString = ( attributes, object ) => {

    let string = '';
    for( let i in attributes ){
      let attr = attributes[i];
      let value = object[attr];
      string += value;
    }

    return string;

  }
  buildClass( row ){

    const {entity,producttype} = row;
    let blockClass = '';
    let hash = false;


    if( entity === 'SogProduct' ){

      let {object} = row;
      object = JSON.parse(object);

      let vars = {

        tape      : object.tape,
        diameter  : object.diameter,
        hole_quantity : object.hole_quantity,
        shape     : object.shape,
        width     : object.width,
        height    : object.height,
        stripe_holes : object.stripe_holes,
        material  : object.material,
        color     : object.color
      };

      vars = sanitizeVars( vars );

      if( typeof object.blocks !== 'undefined' && object.blocks.length > 0 ){
        let i;

        for (i in object.blocks ){
          blockClass = blockClass + this.buildBlockClass( object, i );

        }
      }
        hash = CryptoJS.HmacSHA1( entity+producttype+vars.shape+vars.material+vars.width+vars.height+vars.tape+vars.stripe_holes+vars.diameter+vars.hole_quantity+blockClass+vars.color, '' ).toString();

    } else if( entity === 'Tillbehor' ||  entity === 'Artikel'  ||  entity === 'ShopItem' ) {
        hash = CryptoJS.HmacSHA1( row.pid + entity, '' ).toString();
    } else if( ["TerminalMarking", "terminalMarking", "signMarking"].indexOf( entity ) > -1 ){
        let object = JSON.parse( row.object );
        hash = object?.build_hash || 'nohash';
    }
    return hash;
  }

  buildPackingArray( projectsArray ){

    let projects = [];

    for(let i in projectsArray ){
      let project = projectsArray[i];

      if( typeof project.rows === 'undefined' || project.rows.length <= 0 ) continue;

      let prodArr =  this.buildProductionArray( project.rows  );
      project.rows = prodArr;
      projects.push(project);

    }

    return projects;

  }

  buildProductionArray( rows ){
    let i;
    let hashArr = {};

    if( typeof rows !== 'undefined' && rows.length > 0 ){
      for( i in rows ){
        let r = rows[i];
        let hash = this.buildClass( r );
        //let hash = r.priceClass;

        if( typeof hashArr[hash] !== 'undefined' ){
            hashArr[hash].push( r );
        } else {
          hashArr[hash] = [];
          hashArr[hash].push( r );
        }

      }
    }

    return hashArr;
  }
  getProjectProductTextrows( productClassArray ){

    let productArray = []
    if( productClassArray.length > 0 ){

      let i;
      for(i in productClassArray ){

        let product = productClassArray[i];
        let qty = product.quantity;

        if( typeof product.object === undefined || product.object === null || product.object.length === 0 ){

          productArray.push( { productType: 'ERROR', rows: ['{{{ DATAFEL: '+JSON.stringify( objectDatadump( product ) )+' }}}',] } );

        } else {
          let obj = JSON.parse( product.object );
          let rows = this.getProductTextrows( obj );
          for(let q=0; q<qty; q++ ){
            if( rows.length > 0 )
              productArray.push( { productType: product.producttype, rows: rows, template: product?.template_name } );
          }

        }

      }
    }
    return productArray;
  }

  getProjectTerminalMarkingTextrows( productClassArray ){
    let productArray = []
    if( productClassArray.length > 0 ){

        
      let i;
      for(i in productClassArray ){

        let product = productClassArray[i];
        let qty = product.quantity;

        if( typeof product.object === undefined || product.object === null || product.object.length === 0 ){

            productArray.push( { productType: 'ERROR', rows: ['{{{ DATAFEL: '+JSON.stringify( objectDatadump( product ) )+' }}}',] } );

        } else {
            let obj = JSON.parse( product.object );
            let rows = this.getProductTextrowsObj( obj );
            
            for(let q=0; q<qty; q++ ){
                if( rows.length > 0 ){
                    productArray.push( { 
                        productType: product.producttype,
                        rows: rows,
                        template: obj?.template_name,
                        productName: obj?.product_name
                    });
                }
            }

        }

      }
    }
    return productArray;
  }

    getProductTextrowsObj( product ){
        let rows = [];
        if( typeof product.blocks !== 'undefined' && product.blocks.length > 0 ){
            let b;
            for ( b in product.blocks ){
                let block = product.blocks[b];
                if( typeof block.rows !== 'undefined' && block.rows.length > 0 ){
                    let r;
                    for( r in block.rows ){
                        rows.push( block.rows[r] );
                    }
                }
            }
        }
        return rows;

    }
    
  getProductTextrows( product ){
    let rows = [];

    switch( product.entity ){
        default:
            break;

        case "signMarking": 
            const sBlocks = product.blocks.filter( b => b.type === 'TextBlock' );
            sBlocks.forEach( ( b, i ) => {
                b?.rows?.forEach( r => {
                    rows.push( r );
                })
            })

            break;
        case "SogProduct":
            
            const prodBlocks = [ ...product?.blocks ] || [];

            if( product.blocks.length > 0 ){

                const qrBlock = prodBlocks.find( b => Boolean( b?.isQRcode ) === true );
                const textBlocks = prodBlocks.filter( b => {
                    const qrCode = Boolean( b?.isQRcode ) || false;
                    return qrCode === false && b?.rows?.length > 0;
                });
                
                textBlocks.forEach( block => {
                    if( typeof block.rows !== 'undefined' && block.rows.length > 0 ){
                        block.rows.forEach( r => {
                            rows.push( r );
                        })
                    }
                })

                if( typeof qrBlock !== 'undefined' ){
                    qrBlock.rows.forEach( r => {
                        rows.push( r );
                    })
                }
    /*
                for ( b in product.blocks ){
                let block = product.blocks[b];
                if( typeof block.rows !== 'undefined' && block.rows.length > 0 ){
                let r;
                for( r in block.rows ){
    //                rows.push( block.rows[r].text );
                    rows.push( block.rows[r] );
                }
                }
            }
                */
            
            }
            break;

        case "Artikel":
            rows = JSON.parse(product.textrows);
            break;
    }
    return rows;

  }
  getProductTextrow( object, rowIndex ){
    if( typeof rowIndex === 'undefined' )
      rowIndex = 0;

    const hasBlocks = ( typeof object.blocks !== 'undefined' && object.blocks.length > 0 ? true : false );
    if( !hasBlocks ) return null;

    const blocks = object.blocks.filter( b => typeof b.rows !== 'undefined' && b.rows.length > 0 );
    let i;
    for( i in blocks ){
      let rows = blocks[i].rows;
      return rows[rowIndex].text;
    }
  }

  _sortPacking( rows ){
    const sort4 = this.sort4.current.selectedOptions[0];

    if( sort4.value === 'textrow' ){
//      const index = sort4.index || 0;
      rows.sort(
        firstBy( (row1,row2) => {

            let object1 = JSON.parse(row1.object);
            let object2 = JSON.parse(row2.object);
            let text1 = this.getProductTextrow( object1, 0 );
            let text2 = this.getProductTextrow( object2, 0 );

            if( text1 < text2 )
              return -1;
            else if( text1 > text2)
              return 1;
            else
              return 0;


        },{ ignoreCase:true })
      );
    } else {
      rows.sort( firstBy( sort4.value, { ignoreCase:true, direction: parseInt( sort4.dataset['direction'] ) } ) );
    }
    return rows;
  }

  handlePackSorting = (e) => {
    e.stopPropagation();
    const order = { ...this.state.object };
    const {projects} = order;
    const hasProjects = ( typeof projects !== 'undefined' && projects.length > 0 ? true : false );
    const hash = [];

    if( hasProjects === true ){
      projects.sort(
        firstBy( function(p1,p2 ){ return p1.name - p2.name } )
      );

      for(let i in projects ){
        let project = projects[i];
        let projId = project.id;

        let rows = order.rows.filter( r => r.projectId === projId && r.entity === 'SogProduct' );
        rows = this._sortPacking( rows );
        let projHash = { name: project.name, id: project.id, rows: rows };
        hash.push( projHash );


      }
      let unsorted = order.rows.filter( r => r.projectId === null && (r.entity === 'SogProduct' ) );
      if( unsorted.length > 0 ){
        let projUns =  { "name" : lang['label_unsorted'] , rows: unsorted };
        hash.push( projUns );
      }


    } else {
      let rows = order.rows.filter( r => r.entity === 'SogProduct' );
      rows = this._sortPacking( rows );
      let projHash = { "name" : lang['label_unsorted'] , rows: rows };

      hash.push( projHash );

    }
    let shophash = { name: lang['accessories'], rows: order.rows.filter( o => o.entity === 'Tillbehor' ) };
    let acchash = { name: lang['stickers'], rows: order.rows.filter( o => o.entity === 'ShopItem' ) };
    let arthash = { name: lang['Articles'], rows: order.rows.filter( o => o.entity === 'Artikel' ) };

    if( shophash.rows.length > 0 )
      hash.push( shophash )
    if( acchash.rows.length > 0 )
      hash.push( acchash )
    if( arthash.rows.length > 0 )
      hash.push( arthash );

    let packing = this.buildPackingArray( hash );

    this.setState({ packing: packing, production: false });

  }

  async fetchObject(){
    let self = this;
//    let url = api.api+api.Order.read+'/'+this.props.objectId;
//    let order = false;

    await api.send(
      'Order',
      'order/'+this.props.objectId,
      { includeUser : true },
      {
        type: 'GET',
        success : async function( response ){

          let object = response.data;

          const empresp = await api.send( 'Employee', 'employee/'+object.userId, false, { type:'GET' });
          object.User = empresp.data;

         

          let time = new Date().getTime();
          self.setState({ isUpdated:  time, object: object });
      //    self.props.updateState();
            if( typeof self.props.makeWindowTitle !== 'undefined')
                self.props.updateTitle( self.props.makeWindowTitle( object , ['oid','name']) );


        }
      }
    );



  }

  async componentDidMount(){
    await this.fetchObject();

  }

  doSort = (obj1,obj2, sortValue ) => {
    let value1 = '';
    let value2 = '';

    let comp1 = obj1.object || "{}";
    let comp2 = obj2.object || "{}";


    if( sortValue.indexOf('translation_tag') > -1 ){
      let j1 = JSON.parse(comp1);
      let j2 = JSON.parse(comp2);
      value1 = this.props.lang[j1[sortValue]];
      value2 = this.props.lang[j2[sortValue]];
    } else {

      value1 = obj1[sortValue];
      value2 = obj2[sortValue];
    }



    return ('' + value1).localeCompare(value2);
  }

    sortOrder( order ){
        const sort1 = this.sort1.current.selectedOptions[0];
        const sort2 = this.sort2.current.selectedOptions[0];
        const sort3 = this.sort3.current.selectedOptions[0];
        const self = this;
        const rows = order.rows;
  
        if ( typeof rows === 'undefined' || rows.lenght === 0 ) return false;
        rows.sort(
  //        firstBy( sort1.value, { direction: parseInt(sort1.dataset['direction']) } )
          firstBy(
            function(o1, o2) {
                return self.doSort( o1, o2, sort1.value );
            },
            parseInt(sort1.dataset['direction'])
  
          )
          .thenBy(
            function(o1, o2) {
              return self.doSort( o1, o2, sort2.value );
            },
            parseInt(sort2.dataset['direction'])
  
          ).thenBy(
            function(o1, o2) {
              return self.doSort( o1, o2, sort3.value );
            },
            parseInt(sort3.dataset['direction'])
          )
        );
  
        order.rows = rows;
        return order;
    }

    handleSorting = (e) => {
        e.stopPropagation();
        let object = { ...this.state.object };
        let sortedObject = this.sortOrder( object );
        let rows = sortedObject.rows;
        const products = rows.filter( o => o.entity === 'SogProduct' );


        let prodhash = this.buildProductionArray( products );
        let acchash = this.buildProductionArray( rows.filter( o => o.entity === 'Tillbehor' ) );
        let shophash = this.buildProductionArray( rows.filter( o => o.entity === 'ShopItem' ) );
        let arthash = this.buildProductionArray( rows.filter( o => o.entity === 'Artikel' ) );
        let termhash = this.buildProductionArray( rows.filter( o => ['TerminalMarking', "terminalMarking"].indexOf( o.entity ) ) );
        let signHash = this.buildProductionArray( rows.filter( o => o.entity === 'signMarking' ) );
        const hash = { ...prodhash, ...acchash, ...arthash, ...shophash, ...termhash, ...signHash };
        this.setState( {
            object: object,
            production: hash,
            packing: false
        });

    }

  getRowCount( product ){
    if ( typeof product.blocks === 'undefined' ) return 0;

    let blocks = product.blocks;

    let qty = 0;

    if( blocks.length > 0 ){
      for( let i in blocks ){
        if( typeof blocks[i].rows !== 'undefined' && blocks[i].rows.length > 0 ){
          let rows = blocks[i].rows;
          qty = qty + rows.length;
        }
      }
    }
    return qty;
  }

  download = ( e, prodorPack ) => {

    if( typeof prodorPack === 'undefined' ) prodorPack = 'production';
    let html = ReactDOMServer.renderToStaticMarkup( this.renderDownloadable( prodorPack ) );

    if( this.productionRef.children.length > 0 ){
        fileDownload( html, 'file.html');

    }
    else
      alert( lang['there_is_nothing_to_print_sort_first']);

  }
  print = ( e, prodorPack ) => {

    if( typeof prodorPack === 'undefined' ) prodorPack = 'production';

    let html = ReactDOMServer.renderToStaticMarkup( this.renderPrintable( prodorPack ) );

    if( this.productionRef.children.length > 0 ){
      let win = window.open("", "Title", "toolbar=no,location=yes,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=yes,width=1080,height=auto");
      win.document.write( html );
      win.print();
    }
    else
      alert( lang['there_is_nothing_to_print_sort_first']);


    }

  renderDownloadable( productionorPacking ){

    let productionHTML = ( productionorPacking === 'production' ? this.renderProductionBuild( this.state.production ) : this.renderPackingBuild( this.state.packing ) );

    return (
      <html>
        <head>
          <link rel="stylesheet" href="/assets/packages/fontawesome-free-5.8.1-web/css/all.css"/>
          <style>
            { downloadable }
          </style>

        </head>
        <body className="printable">
          { productionHTML }
        </body>
      </html>

    );
  }
  renderPrintable( productionorPacking ){

    let productionHTML = ( productionorPacking === 'production' ? this.renderProductionBuild( this.state.production ) : this.renderPackingBuild( this.state.packing ) );

    return (
      <html>
        <head>
          <link rel="stylesheet" href="/assets/packages/fontawesome-free-5.8.1-web/css/all.css"/>
          <style>
            { printable }
          </style>
        </head>
        <body className="printable">
          { productionHTML }
        </body>
      </html>

    );
  }

  render(){

    let { production, packing } = this.state;


    let prodorPack = 'production';
    if( packing !== false )
      prodorPack = 'packing';

    const selection = [
      {
        "name"   : "Välj sortering",
        "value"  : -1,
      },
      {
        "name"  : "Produkt a-ö",
        "value" : "translation_tag",
        "direction" : 1
      },
      {
        "name"  : "Produkt ö-a",
        "value" : "translation_tag",
        "direction" : -1
      },
      {
        "name"  : "Material a-ö",
        "value" : "field4",
        "direction" : 1
      },
      {
        "name"  : "Material ö-a",
        "value" : "field4",
        "direction" : -1
      },
      {
        "name"  : "Storlek a-ö",
        "value" : "field2",
        "direction" : 1
      },
      {
        "name"  : "Storlek ö-a",
        "value" : "field2",
        "direction" : -1
      }
    ];

    const pack_selection = [
      {
        "name"    : "Välj sortering",
        "value"   : -1
      },
      {
        "name"  : "Produkt a-ö",
        "value" : "translation_tag",
        "direction" : 1,
        "type"  : "project"
      },
      {
        "name"  : "Produkt ö-a",
        "value" : "translation_tag",
        "direction" : -1,
        "type"  : "project"
      },
      {
        "name"  : "Material a-ö",
        "value" : "field4",
        "direction" : 1,
        "type"  : "project"
      },
      {
        "name"  : "Material ö-a",
        "value" : "field4",
        "direction" : -1,
        "type"  : "project"
      },
      {
        "name"  : "Rad 1",
        "value" : "textrow",
        "direction" : 1,
        "type"  : "project",
        "index" : 0
      }
    ];

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div>
              <h4>1. {lang['sort_for_production']}</h4>
            </div>
            <div className="production-sorting">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="inputGroupSelect01">{ lang['first_sorting'] }</label>
                </div>
                <select className="custom-select" id="sort1" ref={this.sort1} defaultValue="field4">
                {
                  selection.map( (item,i) => {
                    return <option key={i} value={ item.value } data-direction={item.direction}> { item.name }</option>
                  })
                }
                </select>
              </div>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="inputGroupSelect01">{ lang['second_sorting'] }</label>
                </div>
                <select className="custom-select" id="sort2" ref={this.sort2} defaultValue="translation_tag">
                {
                  selection.map( (item,i) => {
                    return <option key={i} value={ item.value } data-direction={item.direction}> { item.name }</option>
                  })
                }
                </select>
              </div>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="inputGroupSelect01">{ lang['third_sorting'] }</label>
                </div>
                <select className="custom-select" id="sort3" ref={this.sort3}>
                {
                  selection.map( (item,i) => {
                    return <option key={i} value={ item.value } data-direction={item.direction}> { item.name }</option>
                  })
                }
                </select>
              </div>
              <div className="btn-group">
                <button className="btn btn-success" onClick={ this.handleSorting }>Sortera</button>
              </div>
            </div>              
          </div>
        </div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-md-12">
          { (production !== false || packing !== false ) &&
            <div className="btn-group" style={{ marginBottom:5 }}>
              <button className="btn btn-light pull-left" onClick={(e) => this.print(e, prodorPack)} title={ lang['print'] }><i className="fas fa-print" /> </button>
              <button className="btn btn-light pull-left" onClick={(e) => this.download(e, prodorPack) } title={ lang['download'] } ><i className="fas fa-download" /> </button>
            </div>
          }
          { production !== false && this.renderProductionBuild( production ) }

          </div>
        </div>
      </div>
    );
  }


/*
<div className="col-md-6">
  <div>
    <h4>2. {lang['sort_for_packing']}</h4>
    <div><small><i>*{lang['is_sorted_by_project_name']}</i></small></div>
    <div className="input-group mb-3">

      <div className="input-group-prepend">
        <label className="input-group-text" htmlFor="inputGroupSelect01">{ lang['second_sorting'] }</label>
      </div>
      <select className="custom-select" id="sort4" ref={this.sort4}>
      {
        pack_selection.map( (item,i) => {
          return <option key={i} value={ item.value } data-direction={item.direction}> { item.name }</option>
        })
      }
      </select>
    </div>
    <div className="btn-group">
      <button className="btn btn-success" onClick={ this.handlePackSorting }>Sortera för packning</button>

    </div>
  </div>
</div>
{ packing !== false && this.renderPackingBuild( packing ) }
*/
  renderPackingBuild( packing ){

    if( packing === false ) return null;

    const content = packing.map( ( project, nr1 ) => {

      let projContent = Object.keys(project.rows).map( ( hash, nr ) => {
        const productClass = project.rows[hash];
        const products = this.getProjectProductTextrows( productClass );
        let first = true;

        const item = productClass[0];
        const qty = this._getProductionItemQuantity( project.rows, hash );
        const object = JSON.parse(item.object);
        const row_quantity = this.getRowCount( object );
        let itemContent = {};

        if( first === true ){
          first = false;

          if( item.entity === "SogProduct" ){
            itemContent = (
              <PackingSortProduct
                key={nr}
                orderRow={ item }
                quantity={ qty }
                rowQuantity={row_quantity}
              >
                <PackingProductRowList
                  items={products}
                />
              </PackingSortProduct>
            );

          } else if( item.entity === "Tillbehor" ){
            itemContent = (
              <PackingSortAccessory
                key={nr}
                orderRow={ item }
                quantity={ qty }
              />
            )
          } else if( item.entity === "ShopItem" ){
            itemContent = (
              <PackingSortShopItem
                key={nr}
                orderRow={ item }
                quantity={ qty }
              />
            )
          }  else if( item.entity === "Artikel" ){
            //let rows = JSON.parse(object.textrows);

            itemContent = (
              <PackingSortArticle
                key={nr}
                orderRow={ item }
                quantity={ qty }
              >
                <PackingProductRowList
                  items={products}
                />
              </PackingSortArticle>
            );
          }

          return itemContent;

        }

        return null;
      });
      return (
        <div key={nr1} className="sorted-project">
        {
          typeof project.name !== 'undefined' &&
          <h4>{ project.id && <small>Projekt: (#{project.id})</small> }  {project.name} </h4>

        }
        { projContent }
        </div>
      )

    });

    return(
      <div className="col-md-12 sorting-content" id={"production_"+this.state.object.oid} ref={ el => (this.productionRef = el )} style={{  padding:'30px 30px 30px 60px', clear:'both' }}>
      { this.renderOrderInfo() }
      { packing !== false &&
        content
      }
      </div>
    );

  }

  renderProductionBuild( production ){
    if( production === false ) return null;
    let display = ( production !== false ? 'block' : 'none' );

    return (
      <div className="col-md-12 sorting-content list-group" id={"production_"+this.state.object.oid} ref={ el => (this.productionRef = el )} style={{  padding:'30px 30px 30px 60px', clear:'both' }}>
      { this.renderOrderInfo() }
      { production !== false &&

        Object.keys(production).map( (hash,nr) => {
          let first = true;

          if( first === true ){
            first = false;

            const item = production[hash][0];
        //    const object = JSON.parse( item.object );
            const qty = this._getProductionItemQuantity(production, hash);

            const products = this.getProjectProductTextrows( production[hash] );
            let content = null;
    
            switch( item.entity ){
                default:
                case "SogProduct":
                    content = (
                    <ProductionSortProduct
                        orderRow={item}
                        quantity={qty}
                        key={nr}
                    >
                        <ProductionProductRowList items={products} />
                    </ProductionSortProduct>
                    );
                    break;


                case "signMarking":
                    content = (
                    <ProductionSortSignMarking
                        orderRow={item}
                        quantity={qty}
                        key={nr}
                        index={nr}
                        
                    >
                        <ProductionProductRowList items={products} />
                    </ProductionSortSignMarking>
                    );
                    break;

                case "TerminalMarking":
                case "terminalMarking":
                
                    const oRows = this.getProjectTerminalMarkingTextrows( production[hash] );
                   
                    content = (
                        <ProductionSortTerminalMarking
                            orderRow={item}
                            quantity={qty}
                            key={nr}
                            index={nr}
                            items={ oRows }
                        />
                    );
                    break;

                case "Tillbehor":
                    content = (
                    <ProductionSortAccessory
                        key={nr}
                        orderRow={ item }
                        quantity={ qty }
                    />
                    );
                    break;

                case "ShopItem":
                    content = (
                        <ProductionSortShopItem
                        key={nr}
                        orderRow={ item }
                        quantity={ qty }
                        />
                    );
                    break;

                case "Artikel":
                    content = (
                        <ProductionSortArticle
                        key={nr}
                        orderRow={ item }
                        quantity={ qty }
                        >
                            <ProductionProductRowList items={products} />
                        </ProductionSortArticle>
                    );
                    break;
            }

            return content;
          }

          return null;

        })

      }
      </div>
    );
  }

  _getProductionItemQuantity( production, hash ){

    let items = production[hash];
    let qty = 0;
    for( let i in items ){
      qty += items[i].quantity;
    }

    return qty;
  }

  renderOrderInfo(){

    const { object:order } = this.state;
    let materials = getOrderMaterials( this.state.object );
    
    let orderContact;
    if( order.contact !== null && order.contact.length > 0 ){
      let phone;
      if( order.phone.length > 0 )
        phone = ", "+order.phone;

      orderContact = <div><div className="label">Leveranskontakt</div><div>{ order.contact }{ phone }</div></div>
    }

    let activeInvoice = null;
    let customerContent = null;
    if( typeof order.User !== 'undefined' ){
      if( typeof order.User.Foretag !== 'undefined' ){
        activeInvoice = order.User.Foretag.ActiveInvoiceAddress;
        let delAddress = false;
        if( order.addressJson !== null && order.addressJson.length > 0 )
          delAddress = JSON.parse(order.addressJson);

        customerContent = (
          <div className="orderInfo">
            <div><b>{ lang['label_customer_number']}:</b> { order.User.Foretag.custnr }</div>
            <div>{ order.User.Foretag.name }</div>
            <div><b>{ lang['label_email_invoice']}: { order.User.Foretag.email }</b></div>
            <div><b>{ lang['label_contact'] }</b></div>
            <div>{ order.User.fnamn } { order.User.enamn }</div>
            <div><b>Telefonnummer:</b></div>
            {
              order.User.phoneNumbers.map( (nr,i) => {
                return <div key={i}>{ nr.telnr }</div>;
              })
            }
            <div style={{ marginTop: 15 }}>
              { orderContact }
            </div>
            <div style={{ marginTop: 15 }}>
              <div><b>{ lang['label_delivery_address'] }</b></div>
              { order.pickup === true && <span className="text-info">{ this.props.lang['will_be_picked_up'] }</span> }
              {
                delAddress !== false &&
                <div>
                  { ( delAddress.company != null ) && <div>{ delAddress.company }</div> }
                  { ( delAddress.company === null || delAddress.company.length === 0 || delAddress.company === false ) && <div>{ order.User.Foretag.name } </div> }
                  <div>{ delAddress.gata }</div>
                  <div>{ delAddress.address2 }</div>
                  <div>{ delAddress.postnr } { delAddress.postort }</div>
                </div>
              }
              { delAddress === false && <div> { lang['no_address_specified']} </div> }
            </div>
            <div style={{ marginTop: 15 }}>
              {
                (activeInvoice !== false && activeInvoice !== null ) &&
                <div>
                  <div><b>{ lang['label_invoice_address'] }</b></div>
                  { ( activeInvoice.company != null ) && <div>{ activeInvoice.company }</div> }
                  { ( activeInvoice.company === null || activeInvoice.company.length === 0 || activeInvoice.company === false ) && <div>{ order.User.Foretag.name } </div> }
                  <div>{ activeInvoice.gata }</div>
                  <div>{ activeInvoice.address2 }</div>
                  <div>{ activeInvoice.postnr } { activeInvoice.postort }</div>
                </div>
              }
              { (activeInvoice === false || activeInvoice === null) && <div><div><b>{ lang['label_invoice_address'] }</b></div> { lang['no_address_registered']} </div> }
            </div>
          </div>
        );
      }
    }
    let y = 0;
    return(
        
      <div className="container-fluid" style={{ marginBottom: 20, paddingBottom:20 , borderBottom: '2px dotted #dadada' }}>
        <div className="row">
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
            <td valign="top">
              <div className="col">

                <h4>{ lang['label_order_name']}: { order.ordername }</h4>
                <div><b>{ lang['label_io_number'] }:</b> { order.ionumber }</div>
                <div><b>{ lang['label_order_number']}:</b> { order.oid }</div>
                <div><b>{ lang['label_order_date'] }:</b> { order.date }</div>
                <div className="card bg-basic text-danger" style={{ marginTop:5, padding:5 }}>
                  <div style={{ fontWeight:'bold' }}>{ lang.order_options['in_case_of_errors'] }</div>
                  { order.changeallowed === null && <div>{ lang.order_options.no_option }</div> }
                  { order.changeallowed !== null && <div>{ lang.order_options.change_allowed[ order.changeallowed ] }</div> }
                  { order.changeallowed > 0 &&
                    <div>{ lang.order_options.edit_options[ order.editoption ] }</div>
                  }
                </div>
                <div><b>{ lang['label_other_info'] }:</b></div>
                <div>{ order.comments }</div>
                { order?.User?.Foretag?.comments?.length > 0 && <div> { order.User.Foretag.comments } </div> }
                <h5 className="text-danger"><b>Pris: { accountingNumbers.se(order.totalprice, true) }</b></h5>
                <svg 
                    width={ 350 }
                    height={'auto'}
                    y={y}
                    x={0}
                    
                    xmlns="http://www.w3.org/2000/svg" 
                    version="1.2"
                    style={{ }}
                                    
                                >


                    { materials.length > 0 && 
                        
                        materials.map( (m,i) => {
                            let bg = { x:0, y:y, fill:m.bgColor, width:20, height:12, stroke:'black', strokeWidth:1 };
                            let t = { x:20, y:y, fill:m.tColor, width:20, height:12, stroke:'black', strokeWidth:1 };
                            let text = { x: 50, fill:'black', fontSize:'11px', dominantBaseline:"auto", y:y+10 };
                            y += 16;
                            return (
                                    <g key={i}>
                                    <rect { ...bg } />
                                    <rect { ...t } />
                                    
                                    <text { ...text }> {m?.tag} {m.material} {m.color} { m?.size }</text>
                                    </g>
                                
                                    
                            );
                        })
                    }
                </svg>
              </div>
              </td>
              <td valign="top">
              <div className="col">
                { customerContent }
              </div>
              </td>
              </tr>
            </tbody>
        </table>
        </div>
      </div>
    );
  }
};
