import { svgSheetConfig } from "./config";
import { getPixelConverter } from './pixelConverter';

export default class Group {

    constructor( direction, envelope, template ){
        this.items = [];
        this.x = 0;
        this.y = 0;
        this.height = 0;
        this.width = 0;
        this.rowQty = 0;
        this.colQty = 0;
        this.designatedSheet = 0;
        this.margin = 0;
        this.pc = getPixelConverter( svgSheetConfig.output_method );
        
        this.template = template;
        this.direction = direction;
        this.envelope = envelope; 
        
    }

    /**
     * @returns the margin between the signs in the group or envelope. this is used when the shape is oval. the machine needs a distance to cut all the signs out
     */
    getItemMargin(){
        return this.template.shape !== 'oval' ? 0 : this.pc.toPixel( this.envelope.constraint.itemMargin );
    }
    /**
     * 
     * @returns this is the margin between the envelopes/groups
     */
    getWrappingMargin(){
        return this.pc.toPixel( this.envelope.constraint.margin );
    }

    getMaxRowsandColumnsByTemplate(){
        return this.envelope.getMaxRowsandColumnsByTemplate( this.template );
    }

    getMaxQuantityByTemplate(){
        let { maxRowQty, maxColQty } = this.getMaxRowsandColumnsByTemplate();
        return maxRowQty * maxColQty;
    }

    setItems( items ){
        if( items.length > 0 ){
            let { constraint } = this.envelope;
            let template = items[0];
            this.margin = this.getWrappingMargin();
            this.items = items;
            let maxGrid = this.getMaxRowsandColumnsByTemplate();
            this.maxColQty = maxGrid.maxColQty;
            this.maxRowQty = maxGrid.maxRowQty;            
        }
        return this;
    }

    getGridQuantity(){
        let quantity = this.items.length; 
        let rows = 0, columns = 0;
        
        if( this.direction === 'vertical' ){     
            rows = Math.min( this.maxRowQty, quantity );
            columns = Math.ceil( Math.min( this.maxColQty, quantity / rows ));
        } else {
            columns = Math.min( this.maxColQty, quantity );
            rows = Math.ceil( Math.min( this.maxRowQty, quantity / columns ) );
        }

        return {
            rows, 
            columns
        }

    }

    calculateMeasurements(){
        let { rows, columns } = this.getGridQuantity();
        
        let height = this.pc.toPixel( this.template.height );
        let width = this.pc.toPixel( this.template.width );
 
        this.rowQty = rows;
        this.colQty = columns;
  
        return {
            width: width * columns + (columns * this.getItemMargin()*2 + this.getWrappingMargin() ) ,
            height: height * rows + (rows * this.getItemMargin()*2 + this.getWrappingMargin() ) 
        };
    }

    getMeasurements(){
        if( this.height === 0 || this.width === 0 ){
           return this.calculateMeasurements();

        } else {
            return { width: this.width, height: this.height }
        }
    }
    getHeight(){
        let { height } = this.getMeasurements();
        return height;
    }

    buildGrid(){
        let x = 0, y = 0;
        let maxHeight = this.pc.toPixel( this.envelope.constraint.height );
        let maxWidth = this.pc.toPixel( this.envelope.constraint.width );
        let first = true;

        this.items.forEach( (item, index) => {
            let margin = this.getItemMargin();
            let qty = index + 1;
            if( first ){
                if( item.shape === 'oval' ){
                    x = margin;
                    y = margin;
                }
                first = false;
            }
            item.x = x;
            item.y = y;
            
            this.items[index] = { ...item };
            let width = this.pc.toPixel( item.width ) + margin;
            let height = this.pc.toPixel( item.height ) + margin;
            
            if( this.direction == 'vertical' ){
                
                if( qty % this.maxRowQty == 0 ){
                    y = margin;
                    x += width + margin;
                }
                else {
                    y += height;
                }

            } else {

                if( qty % this.maxColQty == 0 ){
                    x = margin;
                    y += height;

                } else {
                    x += width + margin;

                }
            }
              
        });

        let { height, width } = this.calculateMeasurements();
      
        this.height = height;
        this.width = width;
        return this;
    }
}