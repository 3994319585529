import React, { Component } from 'react';
import {getPixelConverter} from './pixelConverter';
import {Sign} from './Sign.jsx';
import { svgSheetConfig } from './config';
import { Fragment } from 'react';
import lang from "@/lang/se.json";
import { useMainStore } from '../mainStore.js';

export class SVGSheet extends Component{

    constructor( props ){

        super(props);
        this.pc = getPixelConverter();

        const store = useMainStore.getState();
        
        this.state = {
            ...props.data,
            width: this.pc.convertTo('pixel', store.svgSheet.width ),
            height: this.pc.convertTo('pixel', store.svgSheet.height )
        }
        this.document = React.createRef( props.domId );
    }

    buildCollectionSVG( collection ){
        console.log(' collection margin', collection.margin );
        let collectionSvg = collection.items.map( (product, index) => {
            
            let { x,y } = product;
            let translate = `translate( ${x},${y+collection.margin} )`;

            return(
                <g className="Product" transform={ translate } key={index}>
                    <Sign product={product} pixelConverter={ this.pc } />
                </g>
            );

        });
        
        return { height: collection.height, svg: collectionSvg };
      
    }
 
    renderTopLeft( template, group, config ){
        console.log(' group margin', group.margin );
        let width = group.width - group.margin ;
        let height = group.height - group.margin;
        const firstItem =  group?.items[0];
        const { salesOrderId, tape } = firstItem ;

        console.log(' store ', config.display_envelope_title )
        return (
            <>
                {
                    config.display_envelope_title === true && 
                    <text dominantBaseline="hanging" textAnchor="start" fontSize="15" style={{ fill: '#000', fontFamily:'Gravur Condensed' }} y={ 6 } x={ 3 }>
                        [{ salesOrderId }] { lang[tape]}
                    </text>

                }
            {
                template.shape !== "oval" &&
                <g className="Collection" transform={ `translate(${0}, ${group.margin})` } >
                    <line x1={0} y1={0} y2={0} x2={width} style={{ strokeWidth: '0.2px', stroke:'rgb(255,0,0)' }} />
                    <line x1={0} y1={0} x2={0} y2={height} style={{ strokeWidth: '0.2px', stroke:'rgb(255,0,0)' }} />
                </g>
            }
            </>
        )
    }

    renderSheet( ){
        const store = useMainStore.getState();
        let groups = this.state.groups;
       // let colHeight = 0;
        let svg = groups.map( (group, coli ) => {
            let template = group.items[0];
            let { height, svg: collectionSVG } = this.buildCollectionSVG( group );

        //    colHeight += height;
            let collectionTranslate = `translate( ${group.x}, ${group.y+group.margin} )`;
            
            return ( 
                <g className="Collection" transform={ collectionTranslate } key={ coli }>
                    { this.renderTopLeft( template, group, store.svgSheet ) }

                    { collectionSVG }
                </g>
            );


        });

        return (
            <Fragment>
                <text dominantBaseline="hanging" textAnchor="start" fontSize="15" style={{ fill: '#000', fontFamily:'Gravur Condensed' }} y={ -15 } x="0">
                    { this.props.label } ARK: { this.props.sheetNr }/{ this.props.quantity }
                </text>
                { svg }
            </Fragment>
            );
        
    }

    render( ){
        
        let viewBox = `0 0 ${this.state.width} ${this.state.height}`;

        return (
            <svg
                ref={ this.document }
                width={ this.state.width }
                height={ this.state.height }
                id={this.props.domId}
                xmlns="http://www.w3.org/2000/svg" 
                version="1.2"
                viewBox={ viewBox }
            >               
                <g className="sheet" transform={ 'translate(0,20)' }>
                    { this.renderSheet() }
                </g>
                
            </svg>

        );

    }
}