import { create } from 'zustand'
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { post, get as getRequest, del, patch } from '@/utils/request';
import { svgSheetConfig } from '@/svg/config';
import { filter } from 'jszip';
//localStorage.clear();

const compatibleProducts = ['Rectangular'];

export const useMainStore = create( persist( ( set, get ) => ({
    isAuthenticated: false,
    engine: null,
    authToken: null,
    envelope: { ...svgSheetConfig.collectionConfig },
    svgSheet: { ...svgSheetConfig },
    windows: [],
    ordersToProduce: [],
    materialOrders: [],
    isEditing: false,
    isCreated: false,
    production: [],
    activeProduction: null,
    activeProductionOrders: [],
    pdfFiles: [],
    
    setPdfFiles: async ( files ) => {
        const activeProduction = get().activeProduction;
      //  console.log(' active production in store ', activeProduction );
        const sheets = activeProduction.sheets.map( s => ({ ...s, status: 'done' }));
        const res = await patch( `/items/production_order/${activeProduction.id}`, { sheets: sheets } )
        
        set( produce( state => { 
            state.pdfFiles = files;
            state.activeProduction.sheets = sheets;
        }))
    },

    setActiveProductionOrders: ( orders ) => set( produce( state => { state.activeProductionOrders = orders; } ) ),

    setActiveProduction: ( prodOrder ) => set( produce( state => {

        if( prodOrder?.settings?.envelope )
            state.envelope = prodOrder.settings.envelope;
        if( prodOrder?.settings?.svgSheet )
            state.svgSheet = prodOrder.settings.svgSheet;
        if( prodOrder?.items?.length > 0 ){
            
            state.ordersToProduce = prodOrder.items;
        }

        state.activeProduction = prodOrder;
    })),

    setProduction: ( production ) => set( produce( state => { state.production = production })),

    setCreated: ( status ) => set( produce( state => { state.isCreated = status })),

    addWindow: ( window ) => set( 
        produce( ( state ) => {
            state.windows.push( window );
        })
    ),

    removeWindow: ( windowId ) => set(
        produce( ( state ) => {
            const windows = get().windows.filter( w => w.windowId !== windowId );
            state.windows = [ ...windows ];
        }) 
    ),

    updateWindow: ( updatedWindow ) => set(
        produce( ( state ) => {
            const window = get().windows.find( w => w.windowId === updatedWindow.windowId ) || get().addWindow( updatedWindow );

            const index = get().windows.findIndex( w => w.windowId === updatedWindow.windowId )
            state.windows[ index ] = updatedWindow;
        })
    ),

    toggleEnvelopeTitle: () => set( produce( state => { 
        if( state.envelope.margin < 5 ){
            alert( 'Det går inte att visa rubriken om kuvertmarginalen är mindre än 5' )
            
        } else 
            state.svgSheet.display_envelope_title = !state.svgSheet.display_envelope_title; 
    })),

    setEnvelope: ( config ) => set( produce( state => { state.envelope = config; }) ),

    setEnvelopeSize: ( direction, value ) => set(
        produce( (state) => {
            state.envelope[ direction ] = value;
        })
    ),

    setEnvelopeMargin: ( type, margin ) => set(
        produce( state => {
            if( margin < 0.07 )
                margin = 0.07;

            if( margin < 5 )
                state.svgSheet.display_envelope_title = false; 
            state.envelope[ type ] = margin;
        })
    ),

    setSheet: ( config ) => set( produce( state => { state.svgSheet = config; } ) ),

    setSheetSize: ( direction, value ) => set(
        produce( (state) => {
            state.svgSheet[ direction ] = value;
        })
    ),

    setOrdersToProduce: ( orders ) => set(
        produce( state => {
            state.ordersToProduce = orders;
        })
    ),

    setMaterialOrders: ( orders ) => set(
        produce( state => {
            state.materialOrders = orders;
        })
    ),
    
    setEditing: ( status ) => set( produce( state => { state.isEditing = status; }) ),

    createProduction: async ( selectedOrders ) => {
        
        const now = new Date();
        const formattedDate = now.toISOString();
        
        const salesOrderIds = selectedOrders.map( o => ( o.id )).filter( o => o !== undefined );
        const mos = get().materialOrders.filter( mo => !selectedOrders.some( so => so.id === mo.id ) );

        const res = await post(`/items/production_order`, { 
            createdAt: formattedDate,
            salesOrders: salesOrderIds,
            items: selectedOrders,
            settings: { envelope: get().envelope, svgSheet: get().svgSheet },
            sheets: []
        });

        if( res.code === 201 ){

            set( produce( state => {
                state.activeProduction = { ...res.data };
                state.activeProductionOrders = [ ...state.activeProductionOrders, res.data ];
                state.ordersToProduce = [];
                state.materialOrders = mos;
                state.isCreated = false;
                state.isEditing = true;
            }))
        }

    },
    deleteProductionOrder: async ( podId ) => {

        if( confirm('Detta kommer att radera produktionsorder. Är du säker?') )
        {
            const res = await del(`/items/production_order/${podId}`);

            if( res.code === 200 ){
            //    console.log(' rsponse from deleete ', res );
                set( produce( state => {
                    state.isEditing = false;
                    state.activeProduction = null;
                    state.production = [];
                }))
                await get().loadOrders();
            }
    
        }


    },

    splitOrderByMaterial: ( salesOrders, productionOrders ) => {
        let todo = [];
        todo = salesOrders.reduce((acc, currentOrder) => {
            currentOrder.items.forEach((item) => {
                const product = JSON.parse( item.object );
                
                if( compatibleProducts.includes( product.product_type )){
                    const material = [product.material,product.color].join(" ");
                    if (!acc[`${currentOrder.id}_${material}`]) {
                        acc[`${currentOrder.id}_${material}`] = { ...currentOrder, material, items: [], idx: `${material}_${currentOrder.id}` };
                    }
                    acc[`${currentOrder.id}_${material}`].items.push( { ...item, orderId: currentOrder.id });
    
                }
            });
            return acc;
            }, {}
        );
        
        const orders = Object.values(todo);
        orders.sort( (a, b) => a.material.localeCompare( b.material ) );
    
    
        const filteredOrders = [];
        orders.forEach(o => {
            const isInProduction = productionOrders.some(a => {
                return a.salesOrders.find( so => so.id === o.id );
            });
            if (!isInProduction) {
                filteredOrders.push(o);
            }
        });
    
        return filteredOrders;
        
    },
    createMaterialArray: ( salesOrders ) => {

        
        //CHECK WHICH MATERIALS EXISTS IN THE ORDERS TO PRODUCE AND SAVE THEM INTO AN ARRAY
        let materials = [];
        salesOrders.forEach( o => {
            const items = o.items;
            
            o.items.forEach( i => {
                
                if( compatibleProducts.includes( i.producttype )){
                    const material = i.field4
                    if( i.field4.includes( '<br/>') ){
                        const [d, color] = i.field4.split("<br/>");
                        if( !materials.find( m =>  m.material === i.field4 ) ){
                            materials.push( { material: material, color, items: [] } );
                        }    
                    }
                }
            })
    
        })
    
        //CREATE ORDERS BASED ON MATERIALS
        salesOrders.forEach( o => {
            
            materials.map( (m) => {
                o?.items.forEach( i => {
    
                    if( i.field4 === m.material )
                        m.items.push( i );
                });
               
            })
           
            
        })
    
        return materials;
    
    },

    loadOrders: async () => {
        const res = await getRequest('/items/sales_order?fields=id,ordernr,ordername,createdAt,items.field1,items.field2,items.field4,items.producttype,items.salesOrderId,items.quantity,items.object&filter[status][_in]=order_in_production,order_producing');
        const pres = await getRequest( `/items/production_order?fields=id,name,status,createdAt,salesOrders.*,salesOrders.items.*&sort=-createdAt`);

        if( res.code === 200 && pres.code === 200 ){
            const materialArray = get().createMaterialArray( res.data );
            const filteredOrders = get().splitOrderByMaterial( res.data, pres.data );
            
            set( produce( state => {
                state.materialOrders = filteredOrders;
                state.activeProductionOrders = pres.data;
            }))
           
        }
    }
    
    
}),
{
    name: "localStorage", // unique name
    storage: createJSONStorage( () => localStorage ), // (optional) by default the 'localStorage' is used
}) );
