import { create } from 'zustand'
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { useTranslation } from 'react-i18next';
import { get as getRequest } from '@/utils/request';

const menuSortOrder = {
    crm: 1,
    sales:2,
    operations: 3,
    factory: 4,
    reports: 5
};


function buildMenuTree(modules, scope, parentId = 0) {
    const tree = [];
    
    const children = modules.filter( module => module.parentId === parentId );
  
    for (let child of children) {
        const exists = Object.keys(scope).some( key => key.indexOf( child.definition ) !== -1);
        const childName = child.definition || child.name.toLowerCase().replace(" ","_");
        child.sort_order = menuSortOrder[ childName ];
        if( exists !== null ){
            const subtree = buildMenuTree(modules, scope, child.id);
            if (subtree.length) {
                child.children = subtree;
            }
            tree.push(child);
        }
    }
    
    return tree;
}

export const useMainStore = create( persist( ( set, get ) => ({

    locale: 'se',
    translation: null,
    auth: {},
    menu: [],
    settings: {},
    notifications: [],

    setLocale: ( lang ) => {
        
        set( produce( (state) => { 
            state.locale = lang;
        } ) )
    },

    setAuthenticated: ( authRes ) => {

        set( 
            produce( ( state )  =>  {
                state.auth.id = authRes.id;
                state.auth.name = authRes.name;
                state.auth.token = authRes.token;
                state.auth.expire = authRes.expires;
                state.auth.scope = authRes.scope;
                state.auth.priv = authRes.priv;            
            })
        )
    },

    isAuthenticated: ( ) => {
        const { token, expire } = get().auth;
        const now = Math.floor(Date.now() / 1000);
        if( token !== false && now < expire ){
            return true;
        }
        return false;
        
    },
    clearStore: () => set( produce( (state) => {
        state.auth.length = 0;
        state.menu = [];
        window.sessionStorage.clear();
    })),

    addNotification: ( payload ) => {
        const notification  = produce( payload, draft => {
            const delay = payload.type === 'error' ? 10000 : 3000;
            draft.delay = delay || 3000;
        });
        
        set( produce( state => {
            state.notifications.push( notification );
        }))
        
        return get().notifications;
    },

    removeNotification: ( index ) => set( produce( state => {
        state.notifications = state.notifications.filter( (n,i) => i !== index );
    }))
    
}),
{
    name: "sessionStorage", // unique name
    storage: createJSONStorage( () => sessionStorage ), // (optional) by default the 'localStorage' is used
}) );

