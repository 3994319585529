import React, { useState, useEffect, useRef } from 'react';
import { getFont } from '../config.js'
import QrCode from 'react-qr-code';

const align = {
    left: 'start',
    center: 'middle',
    right: 'end'
};

export default function TextBlock( props ){

    let { data, pixelConverter: px, editable, product: sign } = props;
    let x = data?.position?.left || false;
    let y = data?.position?.top || false;
    let width = data.width || false;
    let height = data.height || false;
    const fontName = data.active_font;
    const textAnchor = align[ data.horizontal_block_alignment ];
    const rows = data.rows;
    const font = getFont( fontName );
    const maximize = data.maximized;
    const textRef = useRef(null);

    const justify = ( alignment, maxWidth ) => {
        let tX;
        const w = maxWidth ? maxWidth : width;
        switch( alignment ){
            case 'left':
                tX = 0;
                break;
            
            default:
            case 'center':
                tX = w / 2;
                break;
            
            case 'right':
                tX = w;

        }

        return tX;
    };

    const containsChars = ( string, chars ) => {
        return chars.test( string );
    }

    /**
     * POSITION BLOCK AND ROW ALIGNMENTS
     */
    useEffect(() => {
        // check everytime if not maximized check longest row
        if ( data.isQRcode !== true && data.isQRcode !== "true" ){
            const pxWidth = px.toPixel( width );
            if( maximize !== true && maximize !== "true" ){

                const tnodes = textRef.current.childNodes;
                let longest = 0;
                let longestIndex = false;
                tnodes.forEach( (r,i) => {
                    const bbox = r.getBBox();
                    if( bbox.width > longest ){

                        longest = bbox.width;
                        if( longest > pxWidth )
                            longest = pxWidth;
                        longestIndex = i;
                    }
                    
                    if( bbox.width > pxWidth ){
                        longest = pxWidth;
                        r.setAttribute('textLength', pxWidth );
                        r.setAttribute('lengthAdjust', 'spacingAndGlyphs');
                    }

                });

                width = longest;
                tnodes.forEach( (r,i) => {
                    const bbox = r.getBBox();
                    if( i !== longestIndex ){
                        const x = justify( data.rows[i].alignment, longest );
                        r.setAttribute('x' , x );       
                    }
                })
            }
        
            if (textRef.current) {

                

                const bbox = textRef.current.getBBox();

                let pY = 0;
                if( x === false ){
                    if( maximize === true || maximize === "true" ){
                        x = Math.max( data.margin_left, sign.margin_left );
                    }
                }
            
                const sHeight = px.toPixel( sign.height );
                const bHeight = px.toPixel( data.height );
                const lastRow = data.rows[ data.rows.length -1 ];
                if( maximize === true || maximize === "true" ){
                    
                    switch( data.vertical_content_alignment ){
                        case "m":
                            pY = ( sHeight - bbox.height )/2;
                            break;
                        case "t":
                            pY = px.toPixel( Math.max( data.margin_top, sign.margin_top ) );
                            break;
                        case "b":
                            pY = px.toPixel( Math.min( (sign.height - sign.margin_top - sign.margin_bottom - lastRow.size ), (sign.height - data.margin_top - data.margin_bottom - lastRow.size  ) ) );
                        default:
                    }
            
                } else {
                    
                    pY = px.toPixel( data.position.top );
                    
                    switch( data.vertical_block_alignment ){
                        case "m":
                            pY = ( sHeight - bbox.height )/2;
                            break;

                        case "t":
                            pY = px.toPixel( Math.max( data.margin_top, sign.margin_top ) );
                            break;
                        
                        case "b":
                            pY = px.toPixel( Math.min( (sign.height - sign.margin_top - sign.margin_bottom - lastRow.size ), (sign.height - data.margin_top - data.margin_bottom - lastRow.size  ) ) );
                            break
                        default:
                    }

                }
                
                if( width === false ){
                    width = Math.min( ( sign.width - x - sign.margin_right ), ( sign.width - x - data.position.right ) );
                }

                if( height === false ){
                    height = Math.min( ( sHeight - pY - px.toPixel( sign.margin_bottom ) ), ( sHeight - pY - px.toPixel( data.position.bottom ) ) );
                }

                const firstRow = { ...rows[0] };
                const ascending = containsChars( firstRow.text, /[ÅÄÖå]/) ? px.toPixel(firstRow.size)*font.ascending_offset : 0;
                
                if( maximize ){
                    
                    pY += px.toPixel(firstRow.size)*font.top_offset;
                
                    if( parseFloat(data.margin_top) !== parseFloat(data.margin_bottom) ){
                        const mdiff = px.toPixel( Math.abs(data.margin_top - data.margin_bottom) )/2;
                        pY += mdiff;
                    }
                
                
                } else {
                    pY += px.toPixel(firstRow.size)*font.top_offset;
                    
                }
                
                pY += ascending;
                textRef.current.setAttribute( 'transform', `translate(${ px.toPixel( x ) } ${ pY  })` );

            }
        } 

    }, [] )
    useEffect( () => {
        
        
           // const firstPath = textRef.current;
            
            if ( data.isQRcode === true && textRef.current.childNodes.length > 1 ) {
                const firstPath = textRef.current.childNodes[0];
                textRef.current.removeChild(firstPath);
            }
          
    }, [] )
    const calculateDy = (index) => {
        // Adjust dy based on the previous line's font size
        const prev = rows[ index - 1 ] || null;
        const row = rows[ index ];

        let prevOffset = 0;
        if( prev !== null ){
            prevOffset = px.toPixel( parseFloat(prev.size)*font.bottom_margin );  
        }
        
        let offset = ( prev?.sameRow === true && row?.sameRow === true  ) ? prevOffset : px.toPixel( parseFloat(row.size) + row.size*font.top_margin ) + parseFloat(prevOffset);

        return offset;
    };

    const renderAsQrCode = ( ) => {

        const qrCodeData = data.rows[0]?.text || '';
        const zIndex = (data.blockNr == data.activeBlock ? zIndex : 0 );

        var style = {
            width         : px.toPixel( data.width),
            height        : px.toPixel( data.width),
            marginLeft    : px.toPixel( data.margin_left),
            marginTop     : px.toPixel( data.margin_top),
            marginRight   : px.toPixel( data.margin_right),
            marginBottom  : px.toPixel( data.margin_bottom),
            color         : data.color_code,
            zIndex        : zIndex,
        };
        
        return(
            <g
                style={{ height: style.height, width: style.width }}
                transform={`translate(${px.toPixel(x)}, ${px.toPixel(y)})`}
                
            >
                <QrCode
                    value={ qrCodeData }
                    size={ style.width }
                    fgColor={ `rgb(0,0,0)` }
                    margin="1"
                    ref={ textRef } 
                />
            </g>
        );
    }
/*
    size={256}
    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
    value={value}
    viewBox={`0 0 256 256`}

    */
    const renderRows = ( rows ) => {
        let ry = 0;

        return (
            
            rows.length > 0 && 
            rows.map( (r,i) => {
                let th = px.toPixel( r.size * font.multiplier );
                let prev = rows[i-1] || null;
                let tSize = px.toPixel( parseFloat(r.size)*font.multiplier );
                ry = ( prev?.sameRow === true && r.sameRow === true ) ? ry : ry += calculateDy(i);
                   
                return (
                    <text 
                        dominantBaseline="auto"
                        style={{ fill: '#000', fontFamily: font.name, letterSpacing: font.letter_spacing, border: '1px solid black' }}
                        fontSize={ `${th}px` }
                        textAnchor={  align[ r.alignment ] }
                        y={ ry } 
                        key={i}
                        x={ `${px.toPixel( justify(  r.alignment ) )}px` }
                    >
                        { r.text }
                    </text>

                )
            })
        );
    };

    const renderAsText =  () => {
        
        return(
            <g 
                onMouseEnter={ props?.editable === true ? () => hover(true) : null }
                onMouseLeave={ props?.editable === true ? () => hover(false) : null }
                ref={ textRef }
            >

                { renderRows( rows ) }

            </g>
        )
    }

    return (
        
        data?.isQRcode === true || data?.isQRcode === "true" ? 
        ( renderAsQrCode( data ) ) : 
        ( renderAsText( data ) )

    );
 }