import React, { Component } from 'react';
import {DataList} from './DataList.jsx'
import {api} from './api';
import moment from 'moment';
import $ from 'jquery';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from "date-fns/locale/sv";
import 'react-datepicker/dist/react-datepicker.css';
import { isPhone, getBrowsingDevice } from '@/utils/mediaDevice.jsx';

import lang from "@/lang/se.json";
import { useMainStore } from '../mainStore.js';

const searchActions = [
  {
    'label'   : lang.order_in_production,
    'module'  : 'OrderModule',
    "type"    : "filter",
    'filterColumn'  : 4,
    'string'  : lang['order_in_production']
  },
  {
    'label'   : lang.order_received,
    'module'  : 'OrderModule',
    "type"    : "filter",
    'filterColumn'  : 4,
    'string'  : lang['order_received']
  },
  {
    'label'   : lang['order_sent'],
    'module'  : 'OrderModule',
    "type"    : "filter",
    'filterColumn'  : 4,
    'string'  : lang['order_sent']
  },
  {
    'label'   : lang['order_active'],
    'module'  : 'OrderModule',
    "type"    : "filter",
    'filterColumn'  : 4,
    'string'  : lang['order_active']
  },
  {
    'label'   : lang['order_canceled'],
    'module'  : 'OrderModule',
    "type"    : "filter",
    'filterColumn'  : 4,
    'string'  : lang['order_canceled']
  }
];
export const orderFormAttributes = {
//        "ordernr"          : { "type" : "text", "label" : "label_order_number", "disabled" : true },
  "ordername"        : { "type" : "text", "label" : "label_order_name", "disabled" : true },
  "ionumber"         : { "type" : "text", "label" : "label_io_number", "disabled" : true  },
  "status"           : { "type" : "select", "label" : "label_order_status" ,"selection" : [ "order_active","order_received", "order_in_production", "order_sent", "order_canceled" ] },
  "linktofreight"    : { "type" : "text", "label" : "label_shipping_link" },
  "minimumvalue"     : { "type" : "number", "label" : "label_minimum_value" },
  "contact"          : { "type" : "text", "label" : "label_contact", "disabled" : true },
  "phone"          : { "type" : "text", "label" : "label_phone_number", "disabled" : true },
  "addressJson"        : { "type" : "text", "label" : "label_delivery_address", "disabled" : true },
  "changeallowed"    : { "type" : "checkbox", "label" : "label_change_allowed" },
};
export const orderFormParams = {
  module    : 'Order',
  formModule: 'OrderFormModule',
  view      : 'OrderView',
  iconSrc   : api.Order.iconSrc,
  api_query : api.Order.read,
  titleColumns: ['oid','ordername'],
  attributes: orderFormAttributes,
  component: 'OrderManager'
};

export class OrderModule extends Component{

  constructor( props ){
    super(props);
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 14);
    this.store = useMainStore.getState();
    this.state = {
      tableProps     : {
        idColumn        : 'oid',
        tableColumns    : ['oid','date','ordername',':Anstalld',':doInvoicePool',':company',':custnr','status','totalprice'],
        childColumns   : {
          ':company'  : 'foretag.name',
          ':custnr' : 'foretag.custnr',
          ':Anstalld' : 'username',
          ':doInvoicePool': 'foretag.doInvoicePool'
        },
        statusColumn  : 7,
        invoicePool : [4],
        disabledSort : [4],
        hasStatusColumn :true,
        columnFloatFix: [8],
        searchFields  : ['oid','ordername','date','status','anstalld.username','foretag.name','foretag.custnr','totalprice'],
        sortColumn    : 1,
        sortDirection : 'desc',
        api_query     : api.Order.dataList,
        search        : 'Mottagen',
        start         : 0,
        length        : 100

      },
      startDate     : startDate,
      endDate       : new Date(),
      module_query  : window.location.pathname.substring(1).split('/'),
      name          : "Orders"
    };

  }

  handleCreate = async (e) => {

    const response = await api.send( 'Order', api.Order.new );
    this.props.openWindow( response.data.id, orderFormParams );
  }

  openWindow = ( objectId, formParams ) => {

    let device = getBrowsingDevice();
    let orgHeight = 640;
    if( isPhone( device ) ){
        orgHeight = device.screen.height - 46; 
    }

    if(typeof formParams !== 'undefined' ){
      let params = { ...orderFormParams, ...formParams, orgWidth:1005, orgHeight, orgLeft:0, padding:0 };
      this.props.openWindow( objectId, params );
    } else
      this.props.openWindow( objectId, orderFormParams );
  }

  handleDatePicker = ( e, key ) => {
    const tableProps = { ...this.state.tableProps };
    let prop;

    this.setState( { [key] : e })

  }

  filterByDate = () => {

    let search = $('#searchbox').val();
    let start = $('#startDate').val();
    let length = $('#endDate').val();
    const tableProps = { ...this.state.tableProps };

    tableProps.search = search;
    tableProps.startDate = start;
    tableProps.lengthDate = length;

    this.setState({ tableProps:tableProps });
  }


  render(){
      const datePicker = (
        <div className="input-group" style={{ marginBottom:6}}>
          <DatePicker
            id={"startDate"}
            className={"datepicker form-control"}
            selected={ this.state.startDate }
            onSelect={(e) => this.handleDatePicker( e, 'startDate' ) }
            name="startDate"
            dateFormat="yyyy-MM-dd"
          />
          <DatePicker
            id={"endDate"}
            className={"datepicker form-control"}
            selected={  this.state.endDate  }
            onSelect={(e) => this.handleDatePicker( e, 'endDate' ) }
            name="endDate"
            dateFormat="yyyy-MM-dd"
          />
          <span className="input-group-append">
            <button className="btn btn-success btn-sm" id="filterDate">{ this.props.lang.filter }</button>
          </span>
        </div>
      );


    return (
      <div className="contaier-fluid">
        <div className="col" style={{ maxWidth: 1400 }}>
          <div id="moduleContent">
            <h3>{ lang[this.state.name] } </h3>
            <div className="ml-3 mr-3 mb-1">{ datePicker }</div>
            <DataList
              handleLogout={this.props.handleLogout}
              filterByDate={ this.filterByDate }
              searchActionLabel={lang['filter_by_status']}
              defaultSearchValue={ lang['order_received'] }
              onCreate={this.handleCreate}
              searchActions={searchActions}
              tableProps={ this.state.tableProps }
              prepareEditableAttributes={this.props.prepareEditableAttributes}
              openWindow={this.openWindow}
              formParams={orderFormParams}
              onChange={this.props.onChange}
              sumColumn={8}
              lang={this.props.lang}
            />
            <div className="card text-danger ml-3 mr-3 pull-right">
              <div className="card-body">
                <div>SUMMA</div><h2 id="sum" className="pull-right" />
              </div>
            </div>
          </div>
        </div>

      </div>
    )

  }
}
