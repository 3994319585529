import { getUnitsByOutputMethod, outputMethod } from "./config";
export default class pixelConverter{

    constructor( units ){
        this.units = units;
    }

    toPixel( value, useFloat ){
        return this.convertTo( 'pixel', value, useFloat );
    }

    convertTo( measurement, value, useFloat ){
        let outputValue; 
        if( measurement === 'pixel' ){
            if( typeof useFloat !== 'undefined' )
                outputValue = ( 100000 * parseFloat(value)*this.units.toPixel )/100000;
            else 
                outputValue = Math.floor( 100000 *(parseFloat(value)*this.units.toPixel) )/100000;

        } else {
            outputValue = parseFloat(value)*this.units.toMM;
        }
 
        return outputValue;
    }

    convertSVGtoPixels( product ){

        let pixel = { ...product };
    
        const convert = [
            'width',
            'height',
            'margin_left',
            'margin_right',
            'margin_top',
            'margin_bottom',
            'hole_distance',
            'hole_size',
            'size',
            'thickness',
            'top',
            'left',
            'right',
            'bottom',
            'diameter'
        ];
    
        for( let prop in product ){
            let value = product[prop];
            if( prop == 'blocks' ) continue;
    
            if( convert.includes( prop ) ){
             
                pixel[prop] = this.toPixel( parseFloat(value) );

            }
    
        }
    /*
        for( let i in pixel.blocks ){
            let block = { ...pixel.blocks[i] };
    
            for( let prop in block ){
                let value = block[prop];
                if( Array.isArray( block[prop] ) ) continue;         
                if( convert.includes( prop ) )
                    block[prop] = this.toPixel( value );
        
            }

            pixel.blocks[i] = block;
        }
      */
        return pixel;
    }
    

}

export function getPixelConverter( method ){
    if( typeof method === 'undefined' )
        method = outputMethod;
    return new pixelConverter( getUnitsByOutputMethod( method ) );
}

