import React from 'react';

const styles = {
    loaderContainer: {
        position: 'relative',
        height: '100%'
    }
}

export default function Loading(){
   

    return (
        <div className="loader-container d-flex justify-content-center align-items-center" style={{ ...styles.loaderContainer }}  >
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}