import React, { useEffect, useState } from "react"
import { useMainStore } from "@/mainStore";
import { svgSheetConfig } from '@/svg/config';
import {firstBy, thenBy, thenByDescending} from 'thenby';
import {SVGSheet} from '../svg/SVGSheet.jsx';
import SVGProduction from '../svg/SVGProductionBuilder.js';
import { sanitizeVars, sanitizeDomId } from '@/utils/objectSanitizer';
import { get, patch } from '@/utils/request';
import { componentBlocks } from "../svg/config.js";
import { colorCodes } from "../config.js";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SVGToPDFConverter from '@/utils/SVGToPDFConverter';
import Loading from "../components/loading.jsx";
import { formatDate } from '@/utils/common';
import Select from 'react-select';
import { produce } from "immer";

const selection = [
    {
      "name"   : "Välj sortering",
      "value"  : -1,
    },
    {
      "name"  : "Produkt a-ö",
      "value" : "translation_tag",
      "direction" : 1
    },
    {
      "name"  : "Produkt ö-a",
      "value" : "translation_tag",
      "direction" : -1
    },
    {
      "name"  : "Material a-ö",
      "value" : "field4",
      "direction" : 1
    },
    {
      "name"  : "Material ö-a",
      "value" : "field4",
      "direction" : -1
    },
    {
      "name"  : "Storlek a-ö",
      "value" : "field2",
      "direction" : 1
    },
    {
      "name"  : "Storlek ö-a",
      "value" : "field2",
      "direction" : -1
    }
];

const statuses = [ "in_production", "finished" ];

const sheetStatuses = ["generated", "done" ];

function colorComboBox( sheetColor ){
    let [bgColor,tColor] = sheetColor.split("/");
    bgColor = colorCodes[ bgColor ];
    tColor = colorCodes[ tColor ];
    
    let bg = { x:0, y:0, fill:bgColor, width:20, height:12, stroke:'black', strokeWidth:1 };
    let t = { x:20, y:0, fill:tColor, width:20, height:12, stroke:'black', strokeWidth:1 };
    
    
    return (
        <svg 
            width={ 40 }
            height={20}
            xmlns="http://www.w3.org/2000/svg" 
            version="1.2"         
        >
            <rect { ...bg } />
            <rect { ...t } />
            
            
        </svg>    
    );
}

export default function SVGProductionModule( { lang } ){

    const { 

        envelope, 
        setEnvelopeSize, 
        svgSheet, 
        setSheetSize, 
        setActiveProduction, 
        activeProduction,
        activeProductionOrders,
        setActiveProductionOrders,
        setEnvelopeMargin,
        ordersToProduce, 
        setOrdersToProduce, 
        isEditing,
        pdfFiles,
        setPdfFiles,
        setEditing,
        isCreated, 
        setCreated, 
        production, 
        setProduction,
        setEnvelope,
        createMaterialArray, 
        splitOrderByMaterial,
        toggleEnvelopeTitle,
        setSheet,
        deleteProductionOrder

    } = useMainStore( state => state );
    const [ hasInitiatedPDFs, setHasInitiatedPDFs ] = useState( false );
    const [ selectedOrderIds, setSelectedOrderIds ] = useState( [] );
    const [ dbEdit, setDbEdit ] = useState( true );
    const [ isLoading, setLoading ] = useState( true );
   
    
    const sheetConfig = svgSheetConfig;

    const downloadSVGFiles = async ( e ) => {

        setHasInitiatedPDFs( true );
        
    }

    const doSort = (obj1,obj2, sortValue, separateValue ) => {
        let value1 = '';
        let value2 = '';
    
        let comp1 = obj1.object || {};
        let comp2 = obj2.object || {};
    
        
        if( sortValue.indexOf('translation_tag') > -1 ){
          let j1 = JSON.parse(comp1);
          let j2 = JSON.parse(comp2);
          value1 = lang[j1[sortValue]];
          value2 = lang[j2[sortValue]];
        } else {
    
          value1 = obj1[sortValue];
          value2 = obj2[sortValue];
        }
    
    
    
        return ('' + value1).localeCompare(value2);
    }

    const sortOrder = ( order ) => {
        
        let rows = [ ...order.items ];
        rows.sort(

            firstBy(
                function(o1, o2){
                    const sign1 = JSON.parse( o1.object );
                    const sign2 = JSON.parse( o2.object );
                    const a1 = parseInt(sign1.width)*parseInt(sign1.height);
                    const a2 = parseInt(sign2.width)*parseInt(sign2.height);
                    return a1 > a2 ? -1 : 1;
                }
            )
            .thenBy(
                function(o1, o2) {
                    const tr1 = o1.textrows[0].replace(/\[.*?\]/, '');
                    const tr2 = o2.textrows[0].replace(/\[.*?\]/, '');
                    return (`${tr1}`).localeCompare(tr2);
                },
               
    
            )
           

        );
 
        return rows;
    }

    const setProductionName = ( name ) => {
        
        setActiveProduction( produce( activeProduction, draft => {
            draft.name = name;
        }));

    }

    const setProductionStatus = ( e ) => {
        setActiveProduction( produce( activeProduction, draft => {
            draft.status = e.target.value;
        }))
    }

    const saveProductionProperty = async ( prop, value ) => {
        await patch( `/items/production_order/${activeProduction.id}`, { [prop]: value });
    }

    const createSVGSheets = ( ordersToProduce ) => {
        let items = [];
        let selectedIds = [];


        ordersToProduce.map( ( order, index ) => {
            
            if( order !== null ){
                const orderItems = sortOrder( order );
                selectedIds.push( order.id );
               
                items = [ ...items, ...orderItems];
    
            }
        });
    
        setSelectedOrderIds( selectedIds );
        let production = new SVGProduction( items );
        const sheets = production.buildProduction();
        setProduction( sheets );
        setCreated( true );
    
        return sheets;
        
    }

    const changeSheetSize = ( direction, value ) => {
        setSheetSize( direction, value );  

    }

    const changeEnvelopeSize = ( direction, value ) => {
        setEnvelopeSize( direction, value );

    }

    const updateSize = async () => {
        
        const productionSheets = createSVGSheets( ordersToProduce );
        const sheets = productionSheets.map( sheet => ({
             
            name: `p_${activeProduction.id}_${sheet.material.replaceAll('_','-')}-${sanitizeDomId(sheet.color)}`,
            productionOrderId: activeProduction.id,
            color: sheet.color,
            material: sheet.material,
            status: 'generated',
            groups: sheet.groups
            
        }))
        
        await patch( `/items/production_order/${activeProduction.id}`, { sheets: sheets, settings: { envelope: envelope, svgSheet: svgSheet } })
        
        setEditing( true );
        setDbEdit( false );
    }

    const closeEditor = () => {
        setLoading( true );
        setActiveProduction( null );
        setProduction( [] );
        setEditing( false );
        
        
    }

    const selectSheetStatus = async (e, item ) => {
        const eee = activeProduction.sheets;
        const sheets = produce( eee, draft => {
      //      console.log(' watta' );
            draft[ item ].status = e.target.value;
        })
       // console.log(' sheets', sheets );
        await patch(`/items/production_order/${activeProduction.id}`, { sheets: sheets })
        setActiveProduction( { ...activeProduction, sheets});
    }


    useEffect( () => {
        const bootstrap = async () => {

            const res = await get( `/items/production_order/${activeProduction.id}?fields=*,items.*` );
            if( res.code === 200 ){
                /*
                const materialArray = res.data.items;
                
                setOrdersToProduce( materialArray );

                createSVGSheets( materialArray );
                    console.log( ' adfter create svg ', res.data );
                if( res.data?.sheets?.length === 0 ){

                    const pItems = production.map( sheet => {
                        console.log(' sheet ', sheet.status );
                        console.log( ' is done ', sheet?.status !== 'done')
                        if( sheet?.status !== 'done' ){
                            
                            return ({ 
                                name: `p_${activeProduction.id}_${sheet.material.replaceAll('_','-')}-${sanitizeDomId(sheet.color)}`,
                                productionOrderId: activeProduction.id,
                                color: sheet.color,
                                material: sheet.material,
                                status: 'generated',
                                groups: sheet.groups
                            });

                        }

                    });
                    console.log(' pitems ', pItems );
                    await patch( `/items/production_order/${activeProduction.id}`, { sheets: pItems })
                    console.log(' datatatatattatatat ', res.data );
                    

                }

                */
                setProduction( res.data.sheets )
                setActiveProduction({ ...res.data } );
                setLoading( false );
            
            }
        }

        bootstrap();

    }, [ isCreated ] )

    let svgId = "id" + Math.random().toString(16).slice(2);
    
    
   // console.log(' active production ', activeProduction.sheets );
    return (
        isLoading ? 
        (
            <Loading />
        ) :
        (
            <div className="d-flex border-card border-top border-left border-right border-bottom ">
                <div className="p-0" >
                    <div className=" align-content-center side-top-panel p-2 bg-light border-right border-bottom border-card " style={{ height:60 }}>
                        <h5 className="m-2">Produktionsorder { activeProduction.id }</h5>
                    </div>
                    <div className="side-panel bg-light border-right border-card p-3" style={{ height: 'calc(100% - 60px)'}}>

                        {
                            !dbEdit &&
                            <>
                                <div>
                                    <h5 className="mt-2 mb-0">{ lang['envelope_size'] }</h5><small>Alla mått i mm</small>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Bredd</span>
                                        </div>
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            title={ "Bredd i millimeter" } 
                                            aria-describedby="basic-addon2" 
                                            aria-label="Username" 
                                            style={{ width:70 }} 
                                            onChange={ (e) => changeEnvelopeSize('width', parseInt( e.target.value )) }
                                            value={ envelope.width } 
                                        />
                                    </div>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Höjd</span>
                                        </div>
                                        <input type="number" className="form-control" title={ "Höjd i millimeter" } style={{ width:70 }} onChange={ (e) => changeEnvelopeSize('height', parseInt( e.target.value ) ) } value={ envelope.height } />
                                    </div>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Distans kuvert</span>
                                        </div>
                                        <input type="number" className="form-control" title={ lang['wrapping_margin'] } step="0.01" min={ 0.07 } style={{ width:70 }} onChange={ (e) => setEnvelopeMargin('margin',parseFloat( e.target.value) ) } value={ envelope.margin } /> 
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1" title={ lang['item_margin']} >Distans skylt</span>
                                        </div>
                                        <input type="number" className="form-control" title={ lang['item_margin'] } step="0.1" style={{ width:70 }} onChange={ (e) => setEnvelopeMargin('itemMargin',parseFloat( e.target.value ) ) } value={ envelope.itemMargin } /> 
                                    </div>
                                    <div className="input-group">
                                        
                                        <input type="checkbox" className="form-control-check mr-2" onChange={ () => toggleEnvelopeTitle() } checked={ svgSheet.display_envelope_title === true && true } /> { lang['display_envelope_title'] }
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <h5>{ lang['sheet_size'] }</h5>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1" title={ lang['item_margin']} >Bredd</span>
                                        </div>
                                        <input type="number" className="form-control" title={ "Bredd i millimeter" } style={{ width:70 }} onChange={ (e) => changeSheetSize('width', parseInt( e.target.value) ) } value={ svgSheet.width } />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1" title={ lang['item_margin']} >Höjd</span>
                                        </div>
                                        <input type="number" className="form-control" title={ "Höjd i millimeter" } style={{ width:70 }} onChange={ (e) => changeSheetSize('height', parseInt( e.target.value) ) } value={ svgSheet.height } />
                                    </div> 
                                </div>
                                
                
                                <div className="mt-2">
                                    <button className="btn btn-primary" onClick={ updateSize }>{ lang['generate'] }</button>
        
                                </div>
                                <div className="mt-2">
                                { 
                                    production.length > 0 &&
                                    <button
                                        className="btn btn-warning"
                                        onClick={(e) => downloadSVGFiles(e) }
                                    >
                                        <i className="fa fa-plus"></i> { lang['create_pdf_files'] }
                                    </button>
                                }
                                </div>
                            </>
                        }


                       
                    </div>
                </div>
                <div className="flex-grow-1" >
                    <div className="app-top-panel bg-light border-bottom border-card d-flex justify-content-between align-items-center" style={{ height:60 }}>
                        <div className="p-1"></div>
                        <div className="p-1">
                            <ul className="nav nav-pills nav-fill">
                                <li className="nav-item">
                                    <button className={`nav-link ${ dbEdit === true ? 'active' : ''}`} href="#" onClick={ () => setDbEdit( true ) } > Redigera </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${ dbEdit === false ? 'active' : '' }`} href="#" onClick={ () => setDbEdit( false ) } >SVG-ark</button>
                                </li>
                            </ul>
                            
                        </div>
                        <div className="p-1">
                            <button className="btn btn-xs btn-custom" onClick={ () => closeEditor() }><i className="fa fa-times close" style={{ width: 15 }}/></button>
                        </div>
                    </div>
                    <div className="app-content" style={{ position: 'relative' }}>

                        
                    {
                        isEditing && !dbEdit &&
                        <>
                            {
                                hasInitiatedPDFs === true &&
                                <div className="p-2 m-4" style={{ minWidth: 225, borderRadius:5, zIndex:1, background: 'rgba(100,100,100,0.2)', position: 'absolute', top:0, right:0 }}>
                                    <div className="">
                                        <SVGToPDFConverter svgPages={ production } label={ activeProduction.id } /> 
                                    </div>
                                </div>
                            }
                            
                            <div className="p-4" style={{ overflowY: 'auto', height: 'calc(100vh - 250px)' }}>

                                
                                <div className="">
                                {
                                    production.length > 0 &&
                                    
                                    production.map( (sheet,i) => {

                                        let j = i+1;
                                      //  console.log( sheet );
                                        let color = sanitizeDomId( sheet.color );
                                        let domId = `p_${activeProduction.id}_${sheet.material.replaceAll('_','-')}-${color}`;
                                        const sheetBg = colorCodes[ sheet.color.split('/')[0] ];
                                        const textC = colorCodes[ sheet.color.split('/')[1]];
                
                                        let svg = ( 
                                            <div className="mb-4 p-2 border-left border-right border-bottom" style={{  width: '1000px', height: 1000, overflow:'hidden', background:'#f1f1f1' }} key={i}>
                                            
                                                <h5>{ `Ark #${j}: ${lang.materials[sheet.material]} ${sheet.color} ${lang[ sheet.tape ] || '' }` } <span className="pull-right">{ colorComboBox( sheet.color ) }</span></h5>
                                                <div className="">
                                                    <TransformWrapper id={ 'wrap'+ svgId } initialScale={1} minScale={0.2} limitToBounds={false} wrapperClass="trasformWrapper">
                                                        <TransformComponent id={ 'tcomp'+ svgId } contentStyle={{ border: `1px solid ${sheetBg}`, background:'white' }}>
                                                            <SVGSheet data={ sheet } domId={domId} key={i} sheetNr={i+1} label={ `[PO#${activeProduction.id}]`} quantity={ production.length } /> 
                                                        </TransformComponent>
                                                    </TransformWrapper>        
                                                </div>        
                                            </div>
                                        );
                
                                        return (svg);
                
                                    })
                                }
                                </div>
                            </div>
                        </>
                    }

                    {
                        isEditing && dbEdit &&
                        <div>
                            
                            
                            <div className="d-flex justify-content-between">
                                <div className="p-3">
                                    
                                    Skapad: { formatDate( activeProduction.createdAt ) }
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{ lang.name }</span>
                                        </div>
                                        <input type="text" value={ activeProduction.name || '' } onChange={ (e) => setProductionName( e.target.value ) } onBlur={ (e) => saveProductionProperty('name', e.target.value ) } className="form-control input" />
                                    </div>
                                    <div className="input-group input-group-sm mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Status</span>
                                        </div>
                                        <select className="form-control" defaultValue={ activeProduction.status } onChange={ (e) => setProductionStatus( e ) } onBlur={ (e) => saveProductionProperty('status', e.target.value ) }>
                                    
                                            {
                                                statuses.map( ( status ,i ) => {
                                                    return ( <option key={"option_"+i} value={ status } >{ lang[status] }</option> );
                                                })
                                            }
                                            
                                        </select>
                                    </div>
                                </div>
                                <div className="p-3">
                                    <button className="btn btn-outline-danger pull-right" onClick={ () => deleteProductionOrder( activeProduction.id ) }><i className="fa fa-trash"></i> Radera</button>
                                </div>

                                
                            </div>
                            <div className="d-flex flex-wrap">
                                <div className="p-3 ">
                                    <h5 className="mt-4">Valda material ordrar</h5>
                                    {

                                        activeProduction.items.map( ( item ,i ) => {
                                            const [material, color] = item.material.split(" ");
                                            const [bg, text] = color.split("/");

                                            return (
                                                <div className="flex-row p-2">
                                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                        <div className="p-1">
                                                            { item.id}
                                                        </div>
                                                        <div className="p-1">
                                                            { item.salesOrderId}
                                                        </div>
                                                        <div className="p-1">
                                                            { lang.materials[ material ] }

                                                        </div>
                                                        <div className="p-1">
                                                            <div 
                                                                    style={{ backgroundColor: colorCodes[ bg ], color: colorCodes[ text ], border:'1px solid black', width: 'auto', height: 30, textAlign:'center' }}
                                                                    className="p-1"
                                                                >
                                                                    {color}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="p-3 " >
                                    
                                    <h5 className="mt-4">Genererade ark</h5>
                                    {
                                        activeProduction?.sheets?.length > 0 ? (
                                            activeProduction.sheets.map( ( sheet, i ) => {
                                             //   console.log(' sheett status ', sheet  );
                                                return (
                                                    <div className="flex-row p-3" key={ `sheet_${i}` }>
                                                        <div className="d-flex flex-wrap">
                                                            <div className="p-2">
                                                                { i+1 }
                                                            </div>
                                                            <div className="p-2">
                                                                { sheet.name }
                                                            </div>
                                                            <div className="p-2">
                                                                { lang.materials[ sheet.material ] }
                                                            </div>
                                                            <div className="p-2">
                                                                { sheet.color }
                                                            </div>
    
                                                            <div className="p-2">
                                                                <select onChange={ (e) => selectSheetStatus( e, i )} defaultValue={ sheet.status }>
                                                                    {
                                                                        sheetStatuses.map( (s, j) => {
                                                                            return(
                                                                                <option key={j} value={s} >{ lang[ s ] }</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
    
                                        ) :
                                        (
                                            <div>Finns inga genererade ark</div>
                                        )
                                    }
                                    
                                </div>
                            </div>
                            
                            
                        </div>
                    }
                    </div>
                </div>
            </div>
        )
 

    )
}